/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {ImpactLevel, Problem, ProblemStatus} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {Metric, Responsive, Tab, TabGroup, Text, Tile, TrendText, victoryBarPlugin,} from '@amzn/meridian';
import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Legend, {LegendProvider} from '@amzn/meridian/legend';
import victoryLinePlugin from '@amzn/meridian/legend/plugin/victory-line';
import victoryTooltipPlugin from '@amzn/meridian/legend/plugin/victory-tooltip';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Select, {SelectOption} from '@amzn/meridian/select';
import useVictoryTheme from '@amzn/meridian/use-victory-theme';
import {useBundle} from '@amzn/react-arb-tools';
import {capitalize} from 'lodash';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLine, VictoryVoronoiContainer,} from 'victory';

import {AppContext} from '../../app';
import {selectSelectedRegion} from '../../state/app/appSlice';
import {listProblems, listProblemTypeDefinitions} from '../../utility/id4-mothership-client';
import PageHeader from '../utility-views/PageHeader';
import {
    calculateAverageResolutionTime,
    calculateResolutionTimePercentChange,
    calculateWeeklyPercentChange,
    formatToMonthDayPadded,
    generateMutedColorFromString,
    getImpactColor,
    getProblemStatusColor,
    impactLevelComparator
} from './GraphUtility';


/**
 * Datapoint interface.
 */
interface DataPoint {
    key: string;
    value: number;
}

/**
 * Dataset interface.
 */
interface Dataset {
    key: string;
    data: DataPoint[];
    label: string;
    color?: string;
}


/**
 * Problem dataset enum.
 */
enum ProblemDataset {
    BY_PROBLEM_TYPE = 'By Problem Type',
    BY_IMPACT_LEVEL = 'By Impact Level',
    BY_PROBLEM_STATUS = 'By Problem Status',
}


/**
 * Tab options.
 */
enum TabOptions {
    WEEK = 'WEEK',
    OVERALL = 'OVERALL'
}

/**
 * Ms per hours.
 */
const MS_PER_HOUR = 3600000;

/**
 * Dashboard component.
 * @class for dashboard.
 */
export const Dashboard = () => {

    const [commonBundle] = useBundle('common.common');
    const MAX_WIDTH = 1800;
    const [alertMessage, setAlertMessage] = useState<{
        type: AlertProps['type'],
        title?: AlertProps['title'],
        message: string
    }>(undefined);
    const [loadingResources, setLoadingResources] = useState<boolean>(true);
    const {webStageConfig} = React.useContext(AppContext);
    const selectedRegion = useSelector(selectSelectedRegion);
    const [allOpenProblems, setAllOpenProblems] = useState<Problem[]>([]);
    const [recentOpenProblems, setRecentlyOpenProblems] = useState<Problem[]>([]);
    const [recentClosedProblems, setRecentlyClosedProblems] = useState<Problem[]>([]);
    const [recentExpiredProblems, setRecentlyExpiredProblems] = useState<Problem[]>([]);
    const [filteredProblems, setFilteredProblems] = useState<Problem[]>([]);
    const [problemTypes, setProblemTypes] = useState<string[]>([]);
    const [selectedProblemType, setSelectedProblemType] = useState<string>('ALL');
    const [selectedDataset, setSelectedDataset] = useState<ProblemDataset>(ProblemDataset.BY_PROBLEM_TYPE);
    const theme = useVictoryTheme({showIndependentGrid: true});
    const [currentTab, setCurrentTab] = useState<TabOptions>(TabOptions.OVERALL);
    const trendPercentage = useMemo(() => calculateWeeklyPercentChange(filteredProblems), [filteredProblems]);
    const lowSevTrendPercentage = useMemo(() => calculateWeeklyPercentChange(filterAllRecentProblems(undefined, [ImpactLevel.MAJOR, ImpactLevel.MAJOR, ImpactLevel.MODERATE])), [filteredProblems]);
    const highSevTrendPercentage = useMemo(() => calculateWeeklyPercentChange(filterAllRecentProblems(undefined, [ImpactLevel.CRITICAL, ImpactLevel.SEVERE])), [filteredProblems]);
    const openProblemTrendPercentage = useMemo(() => calculateWeeklyPercentChange(filterAllRecentProblems([ProblemStatus.OPEN])), [filteredProblems]);
    const expiredAndClosedProblemsTrend = useMemo(() => calculateWeeklyPercentChange(filterAllRecentProblems([ProblemStatus.EXPIRED, ProblemStatus.CLOSED])), [filteredProblems]);
    const resolutionTimeTrend = useMemo(() => calculateResolutionTimePercentChange(filterAllRecentProblems([ProblemStatus.CLOSED])), [filteredProblems]);
    const avgTimeToResolution = useMemo(() => calculateAverageResolutionTime(filterAllRecentProblems([ProblemStatus.CLOSED])), [filteredProblems]);
    const impactLevelTtrDataset = useMemo(() => calculateResolutionTimeChartData(), [filteredProblems]);
    const lineGraphDataSets: Dataset[] = useMemo(() => getDataset(filteredProblems), [filteredProblems, selectedDataset]);


    /**
     * Effect which gets a list of report configurations.
     */
    React.useEffect(() => {
        if (commonBundle !== undefined) {
            const fetchResources = async () => {
                const currentTimeMs = Date.now();
                const oneWeekLookback = 168 * 60 * 60 * 1000;
                const lookback = currentTimeMs - oneWeekLookback;
                setLoadingResources(true);
                let problemTypeDefinitions = await listProblemTypeDefinitions(webStageConfig, selectedRegion);
                setProblemTypes(problemTypeDefinitions.map(pt => pt.problemTypeId).sort());

                let allOpenProblems: Promise<Problem[]> = listProblems(webStageConfig, selectedRegion,
                    {
                        problemStatus: ProblemStatus.OPEN,
                    }
                );

                let openProblems: Promise<Problem[]> = listProblems(webStageConfig, selectedRegion,
                    {
                        problemStatus: ProblemStatus.OPEN,
                        identificationTimeEpochMs: lookback,
                        identificationEndTimeEpochMs: currentTimeMs,
                    }
                );
                let expiredProblems: Promise<Problem[]> = listProblems(webStageConfig, selectedRegion,
                    {
                        problemStatus: ProblemStatus.EXPIRED,
                        identificationTimeEpochMs: lookback,
                        identificationEndTimeEpochMs: currentTimeMs
                    }
                );
                let closedProblems: Promise<Problem[]> = listProblems(webStageConfig, selectedRegion,
                    {
                        problemStatus: ProblemStatus.CLOSED,
                        identificationTimeEpochMs: lookback,
                        identificationEndTimeEpochMs: currentTimeMs
                    }
                );

                await allOpenProblems.then(results => {
                    setAllOpenProblems(results);
                });
                await openProblems.then(results => {
                    setRecentlyOpenProblems(results);
                });
                await closedProblems.then(results => {
                    setRecentlyClosedProblems(results);
                });
                await expiredProblems.then(results => {
                    setRecentlyExpiredProblems(results);
                });
            };

            fetchResources()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading known resources.'
                    });
                    console.error(err);
                })
                .finally(() => setLoadingResources(false));
        }
    }, [commonBundle, selectedRegion]);


    /**
     * Effect which gets a list of report configurations.
     */
    React.useEffect(() => {
        setFilteredProblems([...recentOpenProblems, ...recentClosedProblems, ...recentExpiredProblems]
            .filter(p => selectedProblemType === p.problemType || selectedProblemType === 'ALL'));
    }, [recentOpenProblems, recentClosedProblems, recentExpiredProblems, selectedProblemType]);


    /**
     * Calculates average resolution time by impact level.
     * @param problems to calculate resolution time for.
     * @returns {Record<string, number>} Record where key is impact level and value is average resolution time in milliseconds
     */
    function calculateAverageResolutionTimeByImpactLevel(problems: Problem[]): Record<string, number> {
        const problemTypes = [...new Set(problems.map(problem => problem.impactLevel))];

        return problemTypes.reduce((acc, type) => {
            const problemsOfImpact = problems.filter(problem => problem.impactLevel === type);
            acc[type] = calculateAverageResolutionTime(problemsOfImpact);
            return acc;
        }, {} as Record<string, number>);
    }

    /**
     * Formats the resolution time by impact level for a chart.
     * @returns {Dataset[]} the datasets.
     */
    function calculateResolutionTimeChartData(): Dataset[] {
        const resolutionTimesByImpact = calculateAverageResolutionTimeByImpactLevel(filterAllRecentProblems([ProblemStatus.CLOSED]));

        let datasets = Object.entries(resolutionTimesByImpact).map(([impactLevel, time]) => {
            return ({
                key: impactLevel,
                label: capitalize(impactLevel),
                color: getImpactColor(impactLevel),
                data: [
                    {
                        key: 'Past Week',
                        value: Number((time / MS_PER_HOUR).toFixed(2))
                    }
                ]
            });
        });

        datasets.sort((a, b) => impactLevelComparator(a.label, b.label));
        datasets.push(
            {
                key: 'All',
                label: 'All',
                color: '#B1BAC3',
                data: [
                    {
                        key: 'Past Week',
                        value: Number((avgTimeToResolution / MS_PER_HOUR).toFixed(2))
                    }
                ]
            }
        );
        return datasets;
    }


    /**
     * Converts the problems into multiple data sets grouped by problem type and date.
     * If we have over 20 problem types, we group the bottom into an "other" category.
     * @param problems to convert.
     * @returns {Dataset[]} the datasets.
     */
    function generateLineGraphDatasetsByType(problems: Problem[]): Dataset[] {
        const groupedProblems = new Map<string, Map<string, number>>();
        const totalsByType = new Map<string, number>();
        const allDates = new Set<string>();

        problems.forEach(problem => {
            const date = new Date(problem.creationTimeEpochMs);
            date.setHours(0, 0, 0, 0);
            let dateString = formatToMonthDayPadded(date);
            allDates.add(dateString);

            if (!groupedProblems.has(problem.problemType)) {
                groupedProblems.set(problem.problemType, new Map());
            }
            const typeMap = groupedProblems.get(problem.problemType)!;
            typeMap.set(dateString, (typeMap.get(dateString) || 0) + 1);

            totalsByType.set(problem.problemType, (totalsByType.get(problem.problemType) || 0) + 1);
        });

        const sortedDates = Array.from(allDates).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        const sortedTypes = Array.from(totalsByType.entries())
            .sort((a, b) => b[1] - a[1])
            .map(([type]) => type);

        const datasets: Dataset[] = [];

        const topTypes = sortedTypes.slice(0, groupedProblems.size > 20 ? 19 : 20);

        topTypes.forEach(problemType => {
            const typeMap = groupedProblems.get(problemType)!;
            const dataset: Dataset = {
                key: problemType,
                label: problemType,
                color: generateMutedColorFromString(problemType),
                data: sortedDates.map(dateString => ({
                    key: dateString,
                    value: typeMap.get(dateString) || 0
                }))
            };
            datasets.push(dataset);
        });

        if (groupedProblems.size > 20) {
            const otherMap = new Map<string, number>();
            groupedProblems.forEach((typeMap, problemType) => {
                if (!topTypes.includes(problemType)) {
                    sortedDates.forEach(date => {
                        const count = typeMap.get(date) || 0;
                        otherMap.set(date, (otherMap.get(date) || 0) + count);
                    });
                }
            });

            const otherDataset: Dataset = {
                key: 'Other',
                label: 'Other',
                color: generateMutedColorFromString('Other'),
                data: sortedDates.map(dateString => ({
                    key: dateString,
                    value: otherMap.get(dateString) || 0
                }))
            };
            datasets.push(otherDataset);
        }

        datasets.sort((a, b) => a.label.localeCompare(b.label));
        return datasets;
    }

    /**
     * Converts the problems into multiple data sets grouped by impact level and date.
     * @param problems to convert.
     * @returns {Dataset[]} the datasets.
     */
    function generateLineGraphDatasetsByImpactLevel(problems: Problem[]): Dataset[] {
        const groupedProblems = new Map<string, Map<string, number>>();
        const allDates = new Set<string>();

        problems.forEach(problem => {
            const date = new Date(problem.creationTimeEpochMs);
            date.setHours(0, 0, 0, 0);
            let dateString = formatToMonthDayPadded(date);
            allDates.add(dateString);

            if (!groupedProblems.has(problem.impactLevel)) {
                groupedProblems.set(problem.impactLevel, new Map());
            }
            const impactMap = groupedProblems.get(problem.impactLevel)!;
            impactMap.set(dateString, (impactMap.get(dateString) || 0) + 1);
        });

        const sortedDates = Array.from(allDates).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        const datasets: Dataset[] = [];

        groupedProblems.forEach((timeMap, impactLevel) => {
            const dataset: Dataset = {
                key: impactLevel,
                color: getImpactColor(impactLevel),
                label: capitalize(impactLevel),
                data: sortedDates.map(dateString => ({
                    key: dateString,
                    value: timeMap.get(dateString) || 0
                }))
            };
            datasets.push(dataset);
        });
        datasets.sort((a, b) => impactLevelComparator(a.label, b.label));
        return datasets;
    }

    /**
     * Converts the problems into multiple data sets grouped by problem status and date.
     * @param problems to convert.
     * @returns {Dataset[]} the datasets.
     */
    function generateLineGraphDatasetsByProblemStatus(problems: Problem[]): Dataset[] {
        const openByDate = new Map<string, number>();
        const expiredByDate = new Map<string, number>();
        const closedByDate = new Map<string, number>();
        const netOpenByDate = new Map<string, number>();

        const sortedProblems = [...problems].sort((a, b) =>
            a.identificationTimeEpochMs - b.identificationTimeEpochMs
        );

        const allDates = new Set<string>();

        sortedProblems.forEach(problem => {
            const openDate = new Date(problem.identificationTimeEpochMs);
            openDate.setHours(0, 0, 0, 0);
            const openDateStr = formatToMonthDayPadded(openDate);
            openByDate.set(openDateStr, (openByDate.get(openDateStr) || 0) + 1);
            allDates.add(openDateStr);

            if (problem.closureTimeEpochMs) {
                const updateTime = new Date(problem.updateTimeEpochMs);
                updateTime.setHours(0, 0, 0, 0);
                const updateTimeStr = formatToMonthDayPadded(updateTime);
                allDates.add(updateTimeStr);

                if (problem.problemStatus === ProblemStatus.EXPIRED) {
                    expiredByDate.set(updateTimeStr, (expiredByDate.get(updateTimeStr) || 0) + 1);
                } else if (problem.problemStatus === ProblemStatus.CLOSED) {
                    closedByDate.set(updateTimeStr, (closedByDate.get(updateTimeStr) || 0) + 1);
                }
            }
        });

        const sortedDates = Array.from(allDates).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        let runningTotal = 0;
        sortedDates.forEach(date => {
            runningTotal += (openByDate.get(date) || 0);
            runningTotal -= (closedByDate.get(date) || 0);
            runningTotal -= (expiredByDate.get(date) || 0);
            netOpenByDate.set(date, runningTotal);
        });

        return [
            {
                key: ProblemStatus.OPEN,
                label: 'Open',
                color: getProblemStatusColor(ProblemStatus.OPEN),
                data: sortedDates.map(date => ({
                    key: date,
                    value: openByDate.get(date) || 0
                })),
            },
            {
                key: ProblemStatus.CLOSED,
                label: 'Closed',
                color: getProblemStatusColor(ProblemStatus.CLOSED),
                data: sortedDates.map(date => ({
                    key: date,
                    value: closedByDate.get(date) || 0
                })),
            },
            {
                key: ProblemStatus.EXPIRED,
                color: getProblemStatusColor(ProblemStatus.EXPIRED),
                label: 'Expired',
                data: sortedDates.map(date => ({
                    key: date,
                    value: expiredByDate.get(date) || 0
                })),
            },
            {
                key: 'NET_OPEN',
                color: '#B1BAC3',
                label: 'Net Created',
                data: sortedDates.map(date => ({
                    key: date,
                    value: netOpenByDate.get(date) || 0
                })),
            }
        ];
    }


    /**
     * Gets the dataset given the selection.
     * @param problems to get dataset for.
     * @returns {Dataset[]} the datasets.
     */
    function getDataset(problems: Problem[]): Dataset[] {
        switch (selectedDataset) {
            case ProblemDataset.BY_IMPACT_LEVEL:
                return generateLineGraphDatasetsByImpactLevel(problems);
            case ProblemDataset.BY_PROBLEM_TYPE:
                return generateLineGraphDatasetsByType(problems);
            case ProblemDataset.BY_PROBLEM_STATUS:
                return generateLineGraphDatasetsByProblemStatus(problems);
            default:
                return [];
        }
    }

    /**
     * Get the current dataset.
     * @param statuses the problem statuses.
     * @param impactLevels the impact levels.
     * @returns {Problem[]} the filtered problems.
     */
    function filterAllRecentProblems(statuses?: ProblemStatus[], impactLevels?: ImpactLevel[]): Problem[] {
        return filteredProblems.filter(p => (statuses ? statuses.includes(p.problemStatus) : true)
            && (impactLevels !== undefined ? impactLevels.includes(p.impactLevel) : true));
    }

    /**
     * Get the current dataset.
     * @param impactLevels the impact levels.
     * @returns {Problem[]} the filtered problems.
     */
    function filterAllOpenProblems(impactLevels?: ImpactLevel[]): Problem[] {
        return allOpenProblems.filter(p =>
            (selectedProblemType === p.problemType || selectedProblemType === 'ALL')
            && (impactLevels !== undefined ? impactLevels.includes(p.impactLevel) : true)
        );
    }


    return (
        <Column
            width='100%'
            height='100%'
            alignmentHorizontal='center'
            spacingInset='none'
            spacing='none'
            backgroundColor='subdued'
        >
            {
                alertMessage && (
                    <Alert
                        type={alertMessage.type}
                        title={alertMessage.title}
                        onClose={() => {
                            setAlertMessage(undefined);
                        }}
                        data-testid='dashboard-alert'
                    >
                        {alertMessage.message}
                    </Alert>
                )
            }

            <Row
                maxWidth={MAX_WIDTH}
                spacingInset='500'
                wrap='down'
                alignmentHorizontal='justify'
                width='100%'
            >
                <PageHeader headerText={'Dashboard'} data-testid='dashboard-header'/>
                <Select
                    width={'500'}
                    label={'Problem Type'}
                    value={selectedProblemType}
                    onChange={(values) => {
                        setSelectedProblemType(values);
                    }}
                    data-testid='problem-type-select'
                >
                    <SelectOption label={'ALL'}
                        key={'all'}
                        value={'ALL'}/>
                    {
                        problemTypes.map(option => (
                            <SelectOption label={option}
                                key={option}
                                value={option}/>
                        ))
                    }
                </Select>
            </Row>
            <Row
                maxWidth={MAX_WIDTH}
                width='100%'
                wrap='down'
                spacing='500'
                widths='fill'
                alignmentVertical='stretch'
                spacingInset='none 500 500'
            >
                <Column>
                    <Box minWidth={360}>
                        <Responsive
                            query='max-width'
                            props={{
                                graphWidth: {
                                    default: 600
                                },
                                graphHeight: {
                                    default: 300
                                }
                            }}
                        >
                            {({graphWidth}) => (
                                <Column
                                    spacingInset='400 400 400 400'
                                    spacing='none'
                                    height='100%'
                                    type='outline'
                                    backgroundColor='principal'
                                >
                                    <Row alignmentHorizontal={'justify'}>
                                        <Heading level={3}>Problems</Heading>
                                        <Select
                                            width={'500'}
                                            value={selectedDataset}
                                            onChange={(value) => {
                                                setSelectedDataset(value);
                                            }}
                                            data-testid='dataset-select'
                                        >
                                            <SelectOption label={ProblemDataset.BY_PROBLEM_TYPE}
                                                key={ProblemDataset.BY_PROBLEM_TYPE}
                                                value={ProblemDataset.BY_PROBLEM_TYPE}/>
                                            <SelectOption label={ProblemDataset.BY_IMPACT_LEVEL}
                                                key={ProblemDataset.BY_IMPACT_LEVEL}
                                                value={ProblemDataset.BY_IMPACT_LEVEL}/>
                                            <SelectOption label={ProblemDataset.BY_PROBLEM_STATUS}
                                                key={ProblemDataset.BY_PROBLEM_STATUS}
                                                value={ProblemDataset.BY_PROBLEM_STATUS}/>
                                        </Select>
                                    </Row>
                                    {!loadingResources ? (
                                        <LegendProvider
                                            data={lineGraphDataSets}
                                            plugins={[
                                                victoryLinePlugin({theme}),
                                                victoryTooltipPlugin({showCrosshair: true}),
                                            ]}
                                            aria-label='Problem Line Chart'
                                        >
                                            {({getLineProps, voronoiContainerProps, Tooltip}) => (
                                                <Column spacing='none' width='100%'>
                                                    <TrendText
                                                        arrow={trendPercentage > 0 ? 'up' : 'down'}
                                                        color={trendPercentage > 0 ? 'negative' : 'positive'}
                                                        size='medium'
                                                        data-testid='trend-percentage'
                                                    >
                                                        {Math.abs(trendPercentage)}% over this week
                                                    </TrendText>

                                                    <VictoryChart
                                                        theme={theme}
                                                        width={graphWidth}
                                                        containerComponent={
                                                            <VictoryVoronoiContainer
                                                                labels={(datum) => {
                                                                    return datum.datum.value;
                                                                }}
                                                                {...voronoiContainerProps}
                                                            />
                                                        }
                                                        domainPadding={{x: 16}}
                                                        minDomain={{y: 0}}
                                                    >
                                                        <VictoryAxis/>
                                                        <VictoryAxis dependentAxis/>
                                                        {lineGraphDataSets.map(({data, key}) => (
                                                            <VictoryLine
                                                                key={key}
                                                                data={data}
                                                                x='key'
                                                                y='value'
                                                                labels={(datum: DataPoint) => datum.value}
                                                                labelComponent={<Tooltip legendKey={key}/>}
                                                                {...getLineProps(key)}
                                                            />
                                                        ))}
                                                    </VictoryChart>
                                                    <Column overflowY={'scroll'}>
                                                        <Legend direction='horizontal'/>
                                                    </Column>
                                                </Column>
                                            )}
                                        </LegendProvider>
                                    ) : (
                                        <Column
                                            alignmentVertical='center'
                                            alignmentHorizontal='center'
                                            height='100%'
                                            minHeight={280}
                                        >
                                            <Loader data-testid='chart-loader'/>
                                        </Column>
                                    )}
                                </Column>
                            )}
                        </Responsive>
                    </Box>
                </Column>
                <Column>
                    <TabGroup
                        value={currentTab}
                        onChange={setCurrentTab}
                        aria-labelledby='tablist-heading'
                        data-testid='tab-group'
                    >
                        <Tab
                            value={TabOptions.OVERALL}
                            data-testid='tab-overall'
                        >
                            Live Summary
                        </Tab>
                        <Tab
                            value={TabOptions.WEEK}
                            data-testid='tab-week'
                        >
                            Week Summary
                        </Tab>
                    </TabGroup>
                    {currentTab === TabOptions.OVERALL &&
                        <Box minWidth={360}>
                            <Column spacing='300' height='100%' heights={['fit', 'fill', 'fill']}>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='currently-open-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Currently Open
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='currently-open-count'
                                                    >
                                                        {filterAllOpenProblems().length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='currently-open-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='high-impact-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                High Impact Open
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='high-impact-count'
                                                    >
                                                        {filterAllOpenProblems([ImpactLevel.CRITICAL, ImpactLevel.SEVERE]).length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='high-impact-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='low-impact-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Low Impact Open
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='low-impact-count'
                                                    >
                                                        {filterAllOpenProblems([ImpactLevel.MODERATE, ImpactLevel.MINOR, ImpactLevel.MAJOR]).length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='low-impact-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                            </Column>
                        </Box>
                    }
                    {currentTab === TabOptions.WEEK &&
                        <Box minWidth={360}>
                            <Column spacing='300' height='100%' heights={['fit', 'fill', 'fill']}>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='opened-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Opened
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric units='problems' data-testid='opened-count'>
                                                        {filteredProblems.length}
                                                    </Metric>
                                                    <TrendText
                                                        arrow={openProblemTrendPercentage > 0 ? 'up' : 'down'}
                                                        color={openProblemTrendPercentage > 0 ? 'negative' : 'positive'}
                                                        size='medium'
                                                        data-testid='opened-trend'
                                                    >
                                                        {Math.abs(openProblemTrendPercentage)}% over this week
                                                    </TrendText>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='opened-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='closed-expired-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Closed/Expired
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='closed-expired-count'
                                                    >
                                                        {filterAllRecentProblems([ProblemStatus.EXPIRED, ProblemStatus.CLOSED]).length}
                                                    </Metric>
                                                    <TrendText
                                                        arrow={expiredAndClosedProblemsTrend > 0 ? 'up' : 'down'}
                                                        color={expiredAndClosedProblemsTrend > 0 ? 'positive' : 'negative'}
                                                        size='medium'
                                                        data-testid='closed-expired-trend'
                                                    >
                                                        {Math.abs(expiredAndClosedProblemsTrend)}% over this week
                                                    </TrendText>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='closed-expired-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='high-impact-week-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                High Impact Opened
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='high-impact-week-count'
                                                    >
                                                        {filterAllRecentProblems(undefined, [ImpactLevel.CRITICAL, ImpactLevel.SEVERE]).length}
                                                    </Metric>
                                                    <TrendText
                                                        arrow={highSevTrendPercentage > 0 ? 'up' : 'down'}
                                                        color={highSevTrendPercentage > 0 ? 'negative' : 'positive'}
                                                        size='medium'
                                                        data-testid='high-impact-week-trend'
                                                    >
                                                        {Math.abs(highSevTrendPercentage)}% over this week
                                                    </TrendText>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='high-impact-week-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='low-impact-week-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Low Impact Opened
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='low-impact-week-count'
                                                    >
                                                        {filterAllRecentProblems(undefined,
                                                            [ImpactLevel.MODERATE, ImpactLevel.MINOR, ImpactLevel.MAJOR]).length}
                                                    </Metric>
                                                    <TrendText
                                                        arrow={lowSevTrendPercentage > 0 ? 'up' : 'down'}
                                                        color={lowSevTrendPercentage > 0 ? 'negative' : 'positive'}
                                                        size='medium'
                                                        data-testid='low-impact-week-trend'
                                                    >
                                                        {Math.abs(lowSevTrendPercentage)}% over this week
                                                    </TrendText>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='low-impact-week-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='resolution-time-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Average Time to Resolution Hours
                                            </Text>

                                            {!loadingResources ? (
                                                <>
                                                    <TrendText
                                                        arrow={resolutionTimeTrend > 0 ? 'up' : 'down'}
                                                        color={resolutionTimeTrend > 0 ? 'negative' : 'positive'}
                                                        size='medium'
                                                        data-testid='resolution-time-trend'
                                                    >
                                                        {Math.abs(resolutionTimeTrend)}% over this week
                                                    </TrendText>
                                                    <LegendProvider
                                                        data={impactLevelTtrDataset}
                                                        plugins={[victoryBarPlugin({theme}), victoryTooltipPlugin()]}
                                                    >
                                                        {({getBarProps, voronoiContainerProps, Tooltip}) => (
                                                            <Column spacing='none' maxWidth={600}>
                                                                <VictoryChart
                                                                    theme={theme}
                                                                    height={200}
                                                                    containerComponent={
                                                                        <VictoryVoronoiContainer
                                                                            labels={(datum) => datum.datum.value}
                                                                            {...voronoiContainerProps}
                                                                        />
                                                                    }
                                                                >
                                                                    <VictoryAxis/>
                                                                    <VictoryAxis dependentAxis/>
                                                                    <VictoryGroup offset={50}>
                                                                        {impactLevelTtrDataset.map(({data, key}) => (
                                                                            <VictoryBar
                                                                                key={key}
                                                                                data={data}
                                                                                x='key'
                                                                                y='value'
                                                                                barWidth={40}
                                                                                labels={(datum) => datum.value}
                                                                                labelComponent={
                                                                                    <Tooltip
                                                                                        legendKey={key}
                                                                                    />
                                                                                }
                                                                                {...getBarProps(key)}
                                                                            />
                                                                        ))}
                                                                    </VictoryGroup>
                                                                </VictoryChart>
                                                                <Legend direction='horizontal'/>
                                                            </Column>
                                                        )}
                                                    </LegendProvider>
                                                </>

                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='resolution-time-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                            </Column>
                        </Box>
                    }
                </Column>
            </Row>
        </Column>
    );
};

export default Dashboard;
