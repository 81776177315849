/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {ImpactLevel, Problem, ProblemStatus} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';


/**
 * Get problem status color.
 * @param status color.
 * @returns {string} the color.
 */
export function getProblemStatusColor(status: string): string {
    switch (status) {
        case ProblemStatus.CLOSED:
            return '#EC88DC';
        case ProblemStatus.EXPIRED:
            return '#F98771';
        default:
        case ProblemStatus.OPEN:
            return '#36C2B4';

    }
}

/**
 * Get impact level color function.
 * @param impact to get value for.
 * @returns {number} the color value.
 */
export function getImpactColor(impact: string): string {
    switch (impact) {
        case ImpactLevel.MINOR:
            return '#36C2B4';
        case ImpactLevel.MODERATE:
            return '#A588FF';
        case ImpactLevel.MAJOR:
            return '#FFDB86';
        case ImpactLevel.SEVERE:
            return '#FFAE5D';
        default:
        case ImpactLevel.CRITICAL:
            return '#F98771';
    }
}

/**
 * Impact level comparator.
 * @param a impact a.
 * @param b impact b.
 * @returns {number} the num value.
 */
export function impactLevelComparator(a: string, b: string): number {
    return getImpactValue(a) === getImpactValue(b) ? 0 : getImpactValue(a) > getImpactValue(b) ? 1 : -1;
}


/**
 * Get impact level value function.
 * @param impact to get value for.
 * @returns {number} the associated value.
 */
export function getImpactValue(impact: string): number {
    switch (impact.toUpperCase()) {
        case ImpactLevel.MINOR:
            return 5;
        case ImpactLevel.MODERATE:
            return 3;
        case ImpactLevel.MAJOR:
            return 2;
        case ImpactLevel.SEVERE:
            return 1;
        default:
        case ImpactLevel.CRITICAL:
            return 0;
    }
}

/**
 * Formats the date to month/day padded.
 * @param date to format.
 * @returns {string} the formatted date.
 */
export function formatToMonthDayPadded(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}/${day}`;
}


/**
 * Generate a muted color from a string.
 * @param input the input string.
 * @returns {string} the muted color.
 */
export function generateMutedColorFromString(input: string): string {
    const hash = hashString(input);
    const hue = (hash % 360);
    const saturation = 40;
    const lightness = 85 + (hash % 10);
    return hslToHex(hue, saturation, lightness);
}

/**
 * Generate a hash from the string.
 * @param input to generate hash for.
 * @returns {number} the hash.
 */
function hashString(input: string): number {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i);
        hash |= 0;
    }
    return Math.abs(hash);
}

/**
 * Convert HSL to HEX.
 * @param h hue.
 * @param s saturation.
 * @param l lightness.
 * @returns {string} the hex color.
 */
function hslToHex(h: number, s: number, l: number): string {
    const a = s / 100;
    const b = l / 100;

    const k = (n: number) => (n + h / 30) % 12;
    const f = (n: number) =>
        b - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);

    const r = Math.round(255 * f(0));
    const g = Math.round(255 * f(8));
    const bChannel = Math.round(255 * f(4));

    return `#${((1 << 24) + (r << 16) + (g << 8) + bChannel)
        .toString(16)
        .slice(1)
        .toUpperCase()}`;
}


/**
 * Calculate the weekly percentage change.
 * @param problems the problems to calculate it for.
 * @returns {number} the percentage change.
 */
export function calculateWeeklyPercentChange(problems: Problem[]): number {
    const now = Date.now();
    const oneWeekMs = 7 * 24 * 60 * 60 * 1000;
    const halfWeekMs = oneWeekMs / 2;

    const firstHalfStart = now - oneWeekMs;
    const secondHalfStart = now - halfWeekMs;

    const firstHalfCount = problems.filter(problem =>
        problem.creationTimeEpochMs >= firstHalfStart &&
        problem.creationTimeEpochMs < secondHalfStart
    ).length;

    const secondHalfCount = problems.filter(problem =>
        problem.creationTimeEpochMs >= secondHalfStart &&
        problem.creationTimeEpochMs <= now
    ).length;

    if (firstHalfCount === 0) {
        return secondHalfCount > 0 ? 100 : 0;
    }

    const percentChange = ((secondHalfCount - firstHalfCount) / firstHalfCount) * 100;
    return Math.round(percentChange);
}

/**
 * Calculates the average time to resolution.
 * @param problems the problems to calculate resolution time for.
 * @returns {number} the average resolution time.
 */
export function calculateAverageResolutionTime(problems: Problem[]): number {
    const totalTime = problems.reduce((sum, problem) => {
        const timeDiff = problem.closureTimeEpochMs - problem.creationTimeEpochMs;
        return sum + timeDiff;
    }, 0);

    return Math.round(totalTime / problems.length);
}

/**
 * Calculates the resolution time percent change.
 * @param problems to calculate it for.
 * @returns {number} the res time in ms.
 */
export function calculateResolutionTimePercentChange(problems: Problem[]): number {
    if (problems.length === 0) {
        return 0;
    }

    const validProblems = problems.filter(problem =>
        problem.creationTimeEpochMs &&
        problem.closureTimeEpochMs &&
        problem.closureTimeEpochMs > problem.creationTimeEpochMs
    );

    if (validProblems.length === 0) {
        return 0;
    }

    const earliestTime = Math.min(...validProblems.map(p => p.creationTimeEpochMs));
    const latestTime = Math.max(...validProblems.map(p => p.creationTimeEpochMs));

    const midpointTime = earliestTime + ((latestTime - earliestTime) / 2);

    const firstHalfProblems = validProblems.filter(problem =>
        problem.creationTimeEpochMs < midpointTime
    );

    const secondHalfProblems = validProblems.filter(problem =>
        problem.creationTimeEpochMs >= midpointTime
    );

    const getAverageResolutionTime = (problems: Problem[]): number => {
        if (problems.length === 0) {
            return 0;
        }
        const totalTime = problems.reduce((sum, problem) =>
            sum + (problem.closureTimeEpochMs - problem.creationTimeEpochMs), 0
        );
        return totalTime / problems.length;
    };

    const firstHalfAverage = getAverageResolutionTime(firstHalfProblems);
    const secondHalfAverage = getAverageResolutionTime(secondHalfProblems);

    if (firstHalfAverage === 0) {
        return secondHalfAverage > 0 ? 100 : 0;
    }

    const percentChange = ((secondHalfAverage - firstHalfAverage) / firstHalfAverage) * 100;
    return Math.round(percentChange);
}
